import { useEffect, useRef, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../context/CartContext';
import '../../CSS/ProductAddToCart.css';
import ProductCart from '../../Pages/Products/ProductCart';
import AddOnBar from './AddOnBar';
import ConfigCube from './ConfigCube';
import LaminatesInfoHover from './LaminatesInfoHover';
import Sku from './Sku';
import SimpleBar from 'simplebar-react';
import useAuth from '../../hooks/useAuth';
import { updateProductInfo } from '../../ReduxSlices/productState';

const Configurations = () => {
  const {
    configTypes: configOptions,
    configSelected,
    openAddOns,
    product,
    type,
    stageBuilder,
  } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const screenType = useSelector((state) => state.isMobile.value);
  const { addToCart } = useCart();
  const { auth } = useAuth();
  const scrollbarRef = useRef(null);
  // const [remainder, setRemainder] = useState([]);
  const [infoHover, setInfoHover] = useState(false);
  const [showMobileConfigs, setShowMobileConfigs] = useState(false);
  const [configDivHeight, setConfigDivHeight] = useState(50);
  const presetOptions = [];
  const flatArray = [];
  useEffect(() => {
    const productDescDiv = document.getElementById('productDescDiv');
    const height = productDescDiv.clientHeight;
    setConfigDivHeight(stageBuilder ? 486 : 600 - height); //with generate custom
    //setConfigDivHeight(stageBuilder ? 588 : 600 - height);
  }, [product?.productInfo?.ShortDescription]);

  useEffect(() => {
    if (Object.keys(configOptions).length % 4 !== 0) {
      var array = [];
      var count = 4 - (Object.keys(configOptions).length % 4);
      for (let i = 0; i < count; i++) {
        array.push(i);
      }
      // setRemainder(array);
    }
  }, []);

  useEffect(() => {
    if (openAddOns === 'addOn' && Object.keys(configOptions?.addOns).length === 0) {
      dispatch(updateProductInfo({ openAddOns: 'accessory' }));
    }
  }, [openAddOns]);

  return (
    <div
      style={{
        height: configDivHeight,
      }}
      className='config-div'
    >
      {infoHover && <LaminatesInfoHover />}
      <Sku
        presetOptions={presetOptions}
        fullConfigOptions={configOptions}
      />

      {screenType.isMobile && (
        <button
          className={`mobile-config-dropdown-btn ${showMobileConfigs ? 'no-border' : ''}`}
          onClick={() => setShowMobileConfigs(!showMobileConfigs)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShowMobileConfigs(!showMobileConfigs);
            }
          }}
        >
          CONFIGURE YOUR {type}
          {showMobileConfigs ?
            <BsChevronUp className='down-arrow' />
          : <BsChevronDown className='down-arrow' />}
        </button>
      )}
      {!screenType.isMobile || showMobileConfigs ?
        <div className='config-div-inner'>
          <SimpleBar
            ref={scrollbarRef}
            id='scrollbar'
            style={{
              width: 591,
              height: configDivHeight - 55,
            }}
          >
            <div>
              {Object.keys(configOptions).map((optionKey) => {
                if (
                  optionKey.toLowerCase() !== 'addons' &&
                  optionKey.toLowerCase() !== 'accessories'
                ) {
                  const option = configOptions[optionKey];
                  const optionComponents = [];

                  for (const key in option) {
                    if (Object.values(option[key]).some((i) => i.values.length > 1)) {
                      flatArray.push(option[key]);
                    }
                    Object.values(option[key]).forEach((i) => {
                      if (i.values.length <= 1) {
                        presetOptions.push(i);
                      }
                    });
                  }

                  optionComponents.push(
                    Object.keys(option).map((key) => {
                      const subcategory = option[key];

                      return (
                        product?.configOptions &&
                        Object?.keys(product?.configOptions)?.some((value) =>
                          Object.keys(subcategory).includes(value)
                        ) &&
                        Object.values(subcategory).some((i) => i.values.length > 1) && (
                          <div
                            id={key}
                            key={key}
                          >
                            {stageBuilder && key === 'Surface' ?
                              ''
                            : <ConfigCube
                                title={key}
                                key={key}
                                option={subcategory}
                                configSelected={
                                  Object.keys(product?.configOptions).length > 1 ?
                                    configSelected
                                  : subcategory
                                }
                                flatArray={flatArray}
                                setInfoHover={setInfoHover}
                              />
                            }
                          </div>
                        )
                      );
                    })
                  );

                  return optionComponents;
                }
                return null;
              })}

              {Object.keys(configOptions).map((optionKey) => {
                if (optionKey.toLowerCase() === 'addons') {
                  const addOns = configOptions[optionKey];
                  if (Object.keys(addOns).length > 0) {
                    return (
                      <AddOnBar
                        type='addOn'
                        addOnTypes={addOns}
                        key='addOns'
                      />
                    );
                  }
                } else if (optionKey.toLowerCase() === 'accessories') {
                  const accessories = configOptions[optionKey];
                  if (Object.keys(accessories).length > 0) {
                    return (
                      <AddOnBar
                        type='accessory'
                        key='accessories'
                      />
                    );
                  }
                }
                return null;
              })}

              {auth?.roles?.some((i) => i === 3500 || i === 3001) || stageBuilder ?
                <ProductCart
                  addToCart={addToCart}
                  page={'configurations'}
                />
              : ''}
            </div>
          </SimpleBar>
        </div>
      : ''}
    </div>
  );
};

export default Configurations;
