import { useEffect, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { HiCheckCircle } from 'react-icons/hi';
import { RxMinusCircled } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import '../../CSS/AddOns.css';
import { updateProductInfo } from '../../ReduxSlices/productState';
import AddOnCube from './AddOnCube';
const AddOnBar = ({ type, addOnTypes }) => {
  const {
    optionsSelected,
    openAddOns: open,
    allProducts,
    configSelected,
    filteredProducts,
    accessoriesAvailable,
    accessoriesSelected,
    stageBuilder,
  } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const [availableOptions, setAvailableOptions] = useState([]);
  //const [hover, setHover] = useState(false);

  function filterOptions() {
    if (addOnTypes) {
      if (filteredProducts) {
        setAvailableOptions(
          Object.keys(addOnTypes).filter((option) =>
            filteredProducts.some((product) => {
              return Object.keys(product.configOptions).includes(option);
            })
          )
        );
      } else {
        setAvailableOptions(
          Object.keys(addOnTypes).filter((option) =>
            allProducts.some((product) => {
              return Object.keys(product.configOptions).includes(option);
            })
          )
        );
      }
    } else if (type === 'accessory') {
      if (filteredProducts) {
        setAvailableOptions(
          accessoriesAvailable
            .filter((accessory) =>
              filteredProducts.some((product) => {
                return (
                  accessory.accessorySkus?.find((i) =>
                    product.modelNum.includes(i.accessoryFor)
                  ) !== undefined
                );
              })
            )
            .map((accessory) => accessory.name)
        );
      } else {
        setAvailableOptions(
          accessoriesAvailable
            .filter((accessory) =>
              allProducts.some((product) => {
                return (
                  accessory.accessorySkus?.find((i) => i.accessoryFor === product.modelNum) !==
                  undefined
                );
              })
            )
            .map((accessory) => accessory.name)
        );
      }
    }
  }

  function renderOptions() {
    const selectedOptions =
      addOnTypes ?
        Object.keys(addOnTypes).filter((key) => optionsSelected[key] !== undefined)
      : accessoriesSelected.map((a) => a.name);

    if (selectedOptions.length === 0) {
      return <h3 className='config-name-list'>Please Select</h3>;
    } else {
      const selectedText = selectedOptions.map((key) => `${key}`).join('; ');

      return <h3 className='config-name-selected'>{selectedText}</h3>;
    }
  }

  useEffect(() => {
    filterOptions();
  }, [configSelected, optionsSelected, filteredProducts, accessoriesAvailable]);

  useEffect(() => {
    if (type === 'accessory') {
      dispatch(
        updateProductInfo({
          accessoriesSelected: accessoriesSelected.filter((i) =>
            availableOptions.find((optn) => optn === i.name)
          ),
        })
      );
    }
  }, [availableOptions]);

  return (
    <div
      id='addOnBar1'
      style={{ margin: '14px 1px' }}
    >
      <div
        className={`config-cube-new`}
        role='button'
        aria-label={addOnTypes ? 'Add Ons' : 'Accessories'}
        tabIndex={0}
        onClick={(event) => {
          if (configSelected) {
            dispatch(updateProductInfo({ configSelected: false }));
          }

          dispatch(updateProductInfo({ openAddOns: open === type ? false : type }));
          if (!open && !stageBuilder) {
            event.preventDefault();
            event.stopPropagation();
            setTimeout(() => {
              document.getElementById('addOnBar1').scrollIntoView({
                block: 'center',
                inline: 'nearest',
                behavior: 'smooth',
              });
            }, 100);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            if (configSelected) {
              dispatch(updateProductInfo({ configSelected: false }));
            }
            dispatch(updateProductInfo({ openAddOns: open === type ? false : type }));
            if (!open && !stageBuilder) {
              event.preventDefault();
              event.stopPropagation();
              setTimeout(() => {
                document.getElementById('addOnBar1').scrollIntoView({
                  block: 'center',
                  inline: 'nearest',
                  behavior: 'smooth',
                });
              }, 100);
            }
          }
        }}
      >
        <div className='option-bar-container'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          >
            {
              (
                (type === 'accessory' && accessoriesSelected.length > 0) ||
                Object.keys(addOnTypes ?? {})?.some((i) => Object.keys(optionsSelected).includes(i))
              ) ?
                <HiCheckCircle className='check-circle' />
                // <div
                // onMouseEnter={() => {
                //   setHover(true);
                // }}
                // onMouseLeave={() => {
                //   setHover(false);
                // }}
                // className="info-pop"
                // style={{
                //   position: "relative",
                //   margin: "0 4px",
                //   display: "flex",
                //   alignItems: "center",
                //   cursor: "pointer",
                // }}
                // >
              : <RxMinusCircled
                  style={{
                    color: '#2EBF29',
                  }}
                  className='minus-circle'
                />


              /* {hover ? (
                  <div
                    style={{
                      position: "absolute",
                      background: "#00435B",
                      padding: ".5rem",
                      fontSize: "10px",
                      color: "white",
                      fontWeight: "300",
                      textTransform: "none",
                      width: "100px",
                      marginTop: "-4.5rem",
                      marginLeft: "-4rem",
                      borderRadius: ".5rem",
                      boxShadow: "0 0 5px black",
                      textAlign: "center",
                    }}
                  >
                    OPTIONAL CONFIGURATION
                  </div>
                ) : (
                  ""
                )} */
              // </div>
            }
            <h3 className='config-parent-name'>{addOnTypes ? 'Add Ons' : 'Accessories'}</h3>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            {renderOptions()}
            {open === type ?
              <BsChevronUp className='down-arrow' />
            : <BsChevronDown className='down-arrow' />}
          </div>
        </div>
      </div>
      {open === type ?
        <div className='add-on-div'>
          {addOnTypes ?
            Object.keys(addOnTypes).map((i, index) => {
              return (
                <div key={index}>
                  <AddOnCube
                    addOn={addOnTypes[i]}
                    availableOptions={availableOptions}
                  />
                </div>
              );
            })
          : accessoriesAvailable?.map((accessory, index) => (
              <AddOnCube
                addOn={accessory}
                availableOptions={availableOptions}
                key={index}
              />
            ))
          }
        </div>
      : null}
    </div>
  );
};

export default AddOnBar;
