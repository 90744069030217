import { createContext, useEffect, useState } from 'react';
import Axios from 'axios';

const GeneralImageContext = createContext({});

export const GeneralImageProvider = ({ children }) => {
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const [generalImages, setGeneralImages] = useState([]);

  async function getGeneralCloudinaryImages() {
    Axios.post(`${apiAddress}/content/getGeneralImages`, { location: 'website' })
      .then((response) => {
        setGeneralImages(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getGeneralCloudinaryImages();
  }, []);

  return (
    <GeneralImageContext.Provider value={{ generalImages, setGeneralImages }}>
      {children}
    </GeneralImageContext.Provider>
  );
};

export default GeneralImageContext;

