import { useEffect } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import SelectStageItems from './SelectStageItems';
import { calculateDistance, findExposedSides } from './StageFunctions';

function AddGuardrails() {
  const {
    getStageData,
    stageBuild,
    guardrails,
    setGuardrails,
    stageGuardrailData,
    setStageGuardrailData,
    riserGuardrailData,
    setRiserGuardrailData,
    sideGuardrailData,
    setSideGuardrailData,
  } = useStageInfo();

  async function getGuardrailData() {
    await getStageData('Stage Guardrails', stageGuardrailData, setStageGuardrailData);
    await getStageData('Riser Guardrails', riserGuardrailData, setRiserGuardrailData);
    await getStageData('Side Guardrails', sideGuardrailData, setSideGuardrailData);
    //   await Axios.post(`${api_address}/products/getProductData`, {
    //     sku: 'Stage Guardrails',
    //   })
    //     .then((response) => {
    //       setStageGuardrailData(response.data);
    //     })
    //     .catch((err) => console.error(err));
    //   await Axios.post(`${api_address}/products/getProductData`, {
    //     sku: 'Riser Guardrails',
    //   })
    //     .then((response) => {
    //       setRiserGuardrailData(response.data);
    //     })
    //     .catch((err) => console.error(err));
    //   await Axios.post(`${api_address}/products/getProductData`, {
    //     sku: 'Side Guardrails',
    //   })
    //     .then((response) => {
    //       setSideGuardrailData(response.data);
    //     })
    //     .catch((err) => console.error(err));
  }

  function findBestCombination(guardrailData, stageSide) {
    //finds guardrail that fits and then calls helper function recursively with remaining length to find another guardrail that fits,
    //	until the remaining length is smaller than all guardrails. saves the combination in memo. returns the combination that has the least remaining length

    const guardrailLengths = guardrailData.configOptions.config.Size.Width.values
      .map((length) => Number(length.selectionName.replace(/"$/, '')))
      .sort((a, b) => b - a);

    const memo = {};
    function helper(remainingLength) {
      if (remainingLength === 0) {
        return { combination: [], total: 0 };
      }
      if (remainingLength in memo) {
        return memo[remainingLength];
      }

      let bestCombination = { combination: [], total: 0 };

      for (const length of guardrailLengths) {
        if (length <= remainingLength) {
          const result = helper(remainingLength - length);
          const newTotal = result.total + length;
          if (newTotal > bestCombination.total) {
            bestCombination = {
              combination: [length, ...result.combination],
              total: newTotal,
            };
          }
        }
      }

      memo[remainingLength] = bestCombination;
      return bestCombination;
    }

    return helper(stageSide).combination;
  }

  function handleSelect(selectedSides) {
    const holderArray = [];
    selectedSides.forEach((side) => {
      if (!side.product) {
        const item = stageBuild.find((i) => i.uid === side.itemUid);
        const stageSide = Math.round(
          calculateDistance(side.corner1.x, side.corner1.y, side.corner2.x, side.corner2.y)
        );
        //if the side is a combination of riser sides
        if (side.itemUid.toString().includes('_')) {
          holderArray.push({
            ...side,
            product: sideGuardrailData?.skuList.find(
              (sku) => sku.configOptions.Levels?.value === `${side.itemUid.split('_').length}L` //need to find the level based on how many sides are combined
            ),
          });
        } else if (item.productList[0].productInfo.Series === 'Risers') {
          if (stageSide > 70) {
            //only add guardrails to long sides of 24" and 32" risers
            holderArray.push({
              ...side,
              product: riserGuardrailData?.skuList.find(
                (sku) =>
                  sku.configOptions['Riser Height'].value + '"' ===
                    item.productList[0].configOptions.Height?.selectionName &&
                  sku.configOptions.Style?.selectionName ===
                    item.productList[0].configOptions.Style?.selectionName
              ),
            });
          }
        } else if (item.productList[0].productInfo.Series === 'Fixed Height Stages') {
          const guardrailLengths = findBestCombination(stageGuardrailData, stageSide);
          guardrailLengths.forEach((length) => {
            holderArray.push({
              ...side,
              product: stageGuardrailData?.skuList.find(
                (sku) =>
                  Math.abs(Number(sku.configOptions.Width.value)) === length &&
                  sku.configOptions.Style?.selectionName ===
                    item.productList[0].configOptions.Style?.selectionName
              ),
            });
          });
        }
      } else {
        holderArray.push(side);
      }
    });
    setGuardrails(holderArray);
  }

  useEffect(() => {
    getGuardrailData();
  }, []);

  useEffect(() => {
    if (stageGuardrailData && riserGuardrailData && sideGuardrailData) {
      handleSelect(guardrails);
    }
  }, [stageGuardrailData, riserGuardrailData, sideGuardrailData]);

  return (
    <SelectStageItems
      type='guardrails'
      stageBuild={stageBuild}
      selected={guardrails}
      setSelected={handleSelect}
      disable={findExposedSides(stageBuild, true).filter(
        (side) =>
          calculateDistance(side.corner1.x, side.corner1.y, side.corner2.x, side.corner2.y) < 20 ||
          (stageBuild.find((i) => i.uid === side.itemUid)?.productList[0].productInfo.Series ===
            'Risers' &&
            stageBuild.find((i) => i.uid === side.itemUid)?.productList[0].configOptions.Height
              .value < 24)
      )}
    />
  );
}

export default AddGuardrails;
