import { useEffect, useState } from 'react';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import useExportToExcel from '../Shared/useExportToExcel';
import ReusablePopup from '../NPSportal/ReusablePopup';
import OrderInventoryPopup from '../Quickship/OrderInventoryPopup';
import { AiOutlineException } from 'react-icons/ai';
import EditableGrid from '../Quickship/EditableGrid';
import { LuDatabaseBackup } from 'react-icons/lu';
import { FaWandMagicSparkles } from 'react-icons/fa6';
import Loading from '../Shared/Loading';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const ItemInventory = () => {
  const ExportToExcel = useExportToExcel();
  const axiosJWT = useAxiosJWT();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [columns, setColumns] = useState();
  const [notification, setNotification] = useState();
  const [actionData, setActionData] = useState();
  const [negativeOrders, setNegativeOrders] = useState(false);
  const [gridColumns, setGridColumns] = useState();
  const [customOrders, setCustomOrders] = useState(false);
  const [tableInfo, setTableInfo] = useState();

  function getData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/getItemInventory`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function handleExport() {
    axiosJWT
      .get(`${apiAddress}/itemTable/getItemInventory`)
      .then((response) => {
        ExportToExcel(
          response.data,

          'Item Inventory'
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function viewNegativeOrders() {
    setLoading(true);
    axiosJWT
      .post(`${apiAddress}/itemTable/getOrdersForInventory`)
      .then((response) => {
        setNegativeOrders(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function viewCustomOrders() {
    setLoading(true);

    axiosJWT
      .get(`${apiAddress}/itemTable/getCustomOrderData`)
      .then((response) => {
        setCustomOrders(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function getTableInfo(title) {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridTableInfo`, { dataTitle: title })
      .then((response) => {
        setTableInfo(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getGridColumns(title) {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridColumns`, { dataTitle: title })
      .then((response) => {
        if (title === 'itemInventory') {
          setColumns(response.data);
        } else {
          setGridColumns(response.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //   function saveView() {
  //     setNegativeOrders();
  //     setCustomOrders();
  //     setLoading(true);

  //     if (saveData?.length) {
  //       axiosJWT
  //         .post(`${apiAddress}/itemTable/customizeData`, { data: saveData })
  //         .then(() => {
  //           getData();
  //         })
  //         .catch((err) => {
  //           setLoading(false);
  //           console.error(err);
  //         });
  //     }
  //   }

  function saveView(data, truncate) {
    setNegativeOrders();
    setCustomOrders();
    setLoading(true);

    if (data?.length) {
      axiosJWT
        .post(`${apiAddress}/itemTable/saveChangeData`, {
          data: data,
          columns: gridColumns,
          tableInfo: tableInfo,
          preventTruncate: truncate,
        })
        .then(() => {
          getData();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }

  function resetData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/resetData`)
      .then(() => {
        getData();
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }

  function resetPage() {
    setNegativeOrders();
    setCustomOrders();
  }

  function rowAction(row) {
    axiosJWT
      .post(`${apiAddress}/itemTable/getOrdersForInventory`, {
        row,
      })
      .then((response) => {
        if (response.data?.length) {
          setActionData({ popup: true, data: response.data, row: row });
        } else {
          setNotification('No orders for this item');
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const viewDetails = ({ data }) => {
    return (
      <button
        aria-label='action'
        tabIndex={0}
        onClick={() => {
          rowAction(data);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            rowAction();
          }
        }}
        className='basic-blue-button'
      >
        Details
      </button>
    );
  };

  useEffect(() => {
    getGridColumns('itemInventory');
    getData();
  }, []);

  //   useEffect(() => {
  //     if (saveData) {
  //       saveView();
  //     }
  //   }, [saveData]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <>
      {!loading && data && columns ?
        !negativeOrders && !customOrders ?
          <>
            <div>
              <EditableGrid
                buttonComponent={viewDetails}
                saveData={saveView}
                data={data}
                columns={columns}
                title={'Item Inventory'}
                customRows={[
                  { condition: (rowData) => rowData.available_now < 0, className: 'row-negative' },
                  {
                    condition: (rowData) =>
                      rowData.stock_issue_1 < 0 ||
                      rowData.stock_issue_2 < 0 ||
                      rowData.stock_issue_change < 0,
                    className: 'stock-threshold-issue',
                  },
                ]}
                headerComponent={
                  <>
                    <div className={`in-stock-table-header`}>
                      <div
                        style={{ alignItems: 'end' }}
                        className='basic-flex'
                      >
                        <h2>Item Inventory</h2>
                      </div>
                      <div>
                        <>
                          <AiOutlineException
                            title='View orders with issues'
                            style={{
                              cursor: 'pointer',
                              marginRight: '10px',
                            }}
                            onClick={() => {
                              getGridColumns('negativeOrders');
                              getTableInfo('negativeOrders');
                              viewNegativeOrders();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                getGridColumns('negativeOrders');
                                getTableInfo('negativeOrders');
                                viewNegativeOrders();
                              }
                            }}
                          />{' '}
                          <FaWandMagicSparkles
                            title='View customized orders'
                            style={{
                              cursor: 'pointer',
                              marginRight: '10px',
                            }}
                            onClick={() => {
                              getGridColumns('customOrders');
                              getTableInfo('customOrders');
                              viewCustomOrders();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                getGridColumns('customOrders');
                                getTableInfo('customOrders');
                                viewCustomOrders();
                              }
                            }}
                          />{' '}
                        </>

                        <LuDatabaseBackup
                          title='Reset data'
                          style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                          }}
                          onClick={() => {
                            resetData();
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              resetData();
                            }
                          }}
                        />

                        <HiOutlineDocumentDownload
                          title='Download'
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => handleExport()}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleExport();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </>
        : <EditableGrid
            close={resetPage}
            saveData={saveView}
            data={negativeOrders ? negativeOrders : customOrders}
            columns={gridColumns}
            title={negativeOrders ? 'Orders with Issues' : 'Customized Orders'}
            tableInfo={tableInfo}
          />

      : loading ?
        <Loading />
      : ''}

      {actionData && actionData?.popup && (
        <ReusablePopup
          customStyle={'custom-large-popup'}
          open={actionData}
          setOpen={setActionData}
          title={`${actionData?.row?.sku} (${actionData?.row?.location})`}
          innerComponent={<OrderInventoryPopup incomingData={actionData} />}
        />
      )}
      {notification ?
        <div
          style={{
            fontSize: '16px',
            top: '47.7rem',
            marginLeft: '4.8rem',
            width: '520px',
          }}
          className='notice'
        >
          {notification}
        </div>
      : ''}
    </>
  );
};

export default ItemInventory;
