import React, { useEffect, useState } from 'react';
import '../../CSS/products.css';
import SellSheetCube from './SellSheetCube';
import { AdvancedImage } from '@cloudinary/react';
import { FaCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useCart } from '../../context/CartContext';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';
import VideoPlayer from './VideoPlayer';
import useAuth from '../../hooks/useAuth';

const DetailsDisplay = () => {
  const decodeAndFormat = FormatImageLink();
  const {
    product,
    quickShipProducts,
    skuDisplayed,
    customerPrice,
    skuCompleted,
    loadingPrice,
    showQuickshipIcon,
  } = useSelector((state) => state.productState.value);
  const screenType = useSelector((state) => state.isMobile.value);
  const { generalImages } = useGeneralImages();
  const assemblyIcon = generalImages.find((i) => i.publicId === 'Assembly_arbocg')?.imageLink;
  const marketing = generalImages.find((i) => i.publicId === 'MK_Video_jvp7d4')?.imageLink;
  const demo = generalImages.find((i) => i.publicId === 'Demo_Video_cky8wz')?.imageLink;
  const sellsheet = generalImages.find((i) => i.publicId === 'Sell_Sheet_xuwftb')?.imageLink;

  // const premLam = fullResponse?.seriesData?.premLam;
  // const premEdge = fullResponse.premEdge;
  const [showMore, setShowMore] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoSrc, setVideoSrc] = useState();
  const [detailTitles, setDetailTitles] = useState([
    'Description',
    'Media',
    'Dimensions',
    'Shipping Info',
  ]);
  const [selectedDetails, setSelectedDetails] = useState(detailTitles[0]);
  // const navigate = useNavigate();
  const { auth } = useAuth();
  const { notificationData } = useCart();

  const otherLitIcon = generalImages.find(
    (i) => i.publicId === 'General_Icon_without_bg_yhrwep'
  )?.imageLink;

  useEffect(() => {
    if (!detailTitles?.includes(selectedDetails)) {
      setSelectedDetails(detailTitles[0]);
    }
  }, [detailTitles]);

  useEffect(() => {
    if (
      product &&
      (Object.keys(product?.configOptions).length <= 0 || skuCompleted) &&
      (auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
        auth?.roles?.includes(3500)) &&
      !notificationData?.some((i) => i.Path === 'pricing')
    ) {
      if (!detailTitles?.includes('Pricing')) {
        setDetailTitles([...detailTitles, 'Pricing']);
      }
    } else {
      setDetailTitles(detailTitles.filter((i) => i !== 'Pricing'));
    }
  }, [auth]);

  useEffect(() => {
    let holderArray = [...detailTitles];
    if (product?.literature?.filter((i) => i.type === 'Assembly Instructions')?.length > 0) {
      if (!holderArray?.includes('Installation')) {
        holderArray.push('Installation');
      }
    } else {
      holderArray = holderArray.filter((i) => i !== 'Installation');
    }
    if (
      product &&
      (Object.keys(product?.configOptions).length <= 0 || skuCompleted) &&
      (auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
        auth?.roles?.includes(3500)) &&
      !notificationData?.some((i) => i.Path === 'pricing')
    ) {
      if (!holderArray?.includes('Pricing')) {
        holderArray.push('Pricing');
      }
    } else {
      holderArray = holderArray.filter((i) => i !== 'Pricing');
    }

    setDetailTitles(holderArray);
  }, [product, skuDisplayed]);

  // function handleAccesoryClick(item) {
  //   navigate({
  //     pathname: `/products/${item.link}`,
  //     // pathname: "/products/LuvraFlex Series",
  //     // search: `?${createSearchParams(optionsSelected)}`,
  //   });
  // }
  function videoClickHandler(src) {
    setShowVideoPlayer(true);
    setVideoSrc(src);
  }
  function literatureHandler(literature) {
    window.open(`${literature?.full_link}`, '_blank');
  }

  const shippingInfo = product?.shippingInfo;
  function switchDetails() {
    switch (selectedDetails) {
      case 'Description':
        return (
          <div className='description-display'>
            <div
              style={{
                textAlign: 'justify',
                paddingBottom: '1.5rem',
                marginBottom: '1.5rem',
                borderBottom: '1px solid #E1E1E1',
                whiteSpace: 'pre-wrap',
                font: 'normal normal normal 16px/26px Poppins',
                color: '#4B4B4B',
              }}
            >
              {product?.productInfo?.LongDescription}
            </div>

            <div
              className='desc-display-more'
              style={{ display: 'flex' }}
            >
              {(!screenType.isMobile || showMore) && (
                <div className='bullets'>
                  <h2>Specs</h2>
                  <div className='underline'></div>
                  {product?.bullets?.map((i, index) => {
                    return (
                      <div
                        key={index}
                        style={{ margin: '0' }}
                        className='bullet'
                      >
                        <FaCircle
                          style={{
                            height: '7px',
                            width: '7px',
                            position: 'absolute',
                          }}
                        />
                        <div style={{ marginLeft: '25px' }}>
                          {i.Title && <span style={{ fontWeight: 'bold' }}>{i.Title}: </span>}
                          {i.Text}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {screenType.isMobile && (
                <div
                  role='button'
                  aria-label=''
                  tabIndex={0}
                  className='read-more'
                  onClick={() => setShowMore(!showMore)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setShowMore(!showMore);
                    }
                  }}
                >
                  {showMore ? 'Read Less' : 'Read More'}
                </div>
              )}
              <div className='certifications'>
                {product?.certifications?.map((i, index) => {
                  return (
                    <AdvancedImage
                      style={{ cursor: i.fileLink ? 'pointer' : '' }}
                      onClick={() => {
                        if (i.fileLink) {
                          window.open(i.fileLink, '_blank');
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (i.fileLink) {
                            window.open(i.fileLink, '_blank');
                          }
                        }
                      }}
                      key={index}
                      cldImg={decodeAndFormat(i.LogoImage)}
                      // .resize(scale().height(100))}
                      // plugins={[lazyload()]}
                      alt={i.Certifications}
                    />
                  );
                })}
                {showQuickshipIcon ?
                  <img
                    className='lead-time-img'
                    src={generalImages.find((i) => i.publicId === '7_Day_rt9jle')?.imageLink}
                    alt='Lead Time'
                  />
                : product?.productInfo?.LeadTimeImage ?
                  <img
                    className='lead-time-img'
                    src={product.productInfo.LeadTimeImage}
                    alt='Lead Time'
                  />
                : ''}
              </div>
            </div>
          </div>
        );
      case 'Media':
        return (
          <div style={{ display: 'flex' }}>
            {showVideoPlayer ?
              <VideoPlayer
                videoSrc={videoSrc}
                setShow={setShowVideoPlayer}
              />
            : ''}

            {product?.videos?.map((i, index) => (
              <SellSheetCube
                key={index}
                clickHandler={() => {
                  videoClickHandler(i.video_link);
                }}
                text={
                  i.type.toLowerCase() === 'demo' ?
                    'WATCH THE DEMO VIDEO'
                  : 'WATCH THE MARKETING VIDEO'
                }
                img={i.type.toLowerCase() === 'demo' ? demo : marketing}
              />
            ))}
            {product?.literature?.map((i, index) => (
              <SellSheetCube
                key={index}
                clickHandler={() => literatureHandler(i)}
                text={
                  i.text ? i.text
                  : i.type.toLowerCase() === 'sell sheet' ?
                    `DOWNLOAD ${i.type}`
                  : i.type.toLowerCase() === 'other' ?
                    'DOWNLOAD LITERATURE'
                  : i.type
                }
                img={
                  i.type.toLowerCase() === 'sell sheet' ? sellsheet
                  : i.type.toLowerCase() === 'assembly instructions' ?
                    assemblyIcon
                  : i.type.toLowerCase() === 'other' ?
                    otherLitIcon
                  : ''
                }
              />
            ))}
          </div>
        );

      case 'Installation':
        return (
          <div style={{ display: 'flex' }}>
            {product?.literature
              ?.filter((i) => i.type === 'Assembly Instructions')
              .map((i, index) => (
                <SellSheetCube
                  key={index}
                  clickHandler={() => literatureHandler(i)}
                  text={
                    i.text ? i.text
                    : i.type.toLowerCase() === 'sell sheet' ?
                      `DOWNLOAD ${i.type}`
                    : i.type.toLowerCase() === 'other' ?
                      'DOWNLOAD LITERATURE'
                    : i.type
                  }
                  img={
                    i.type.toLowerCase() === 'sell sheet' ? sellsheet
                    : i.type.toLowerCase() === 'assembly instructions' ?
                      assemblyIcon
                    : i.type.toLowerCase() === 'other' ?
                      otherLitIcon
                    : ''
                  }
                />
              ))}
          </div>
        );
      case 'Dimensions':
        return (
          <>
            {product?.dims.map((i, index) => {
              return (
                <div key={index}>
                  {i.Type}: {i.Dims}
                </div>
              );
            })}
          </>
        );
      case 'Shipping Info':
        return (
          <>
            {/* All shipping info */}
            {shippingInfo ?
              Object.keys(shippingInfo)?.map((i, index) => {
                if (
                  shippingInfo[i] &&
                  i.toLowerCase() !== 'list price' &&
                  i.toLowerCase() !== 'number of stools/benches'
                ) {
                  return <div key={index}>{`${i}: ${shippingInfo[i]}`}</div>;
                }
              })
            : ''}
            {/* <div>Ships from: {shippingInfo["Ships From"]}</div> */}
          </>
        );
      case 'Pricing':
        // const shippingInfo = product?.shippingInfo;
        return customerPrice?.basePrice || loadingPrice ?
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '15px' }}>
                {/* All shipping info */}
                <h4 style={{ marginTop: '0px', marginBottom: '5px' }}>List Price:</h4>
                <div
                  style={{
                    height: '20px',
                    borderRadius: '0px',
                  }}
                  className={
                    !customerPrice?.basePrice?.listPrice || loadingPrice ?
                      'loading-price'
                    : 'modelNum'
                  }
                >
                  Item Price: $
                  {parseFloat(customerPrice?.basePrice?.listPrice).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
                <div>
                  {(
                    !loadingPrice &&
                    customerPrice?.basePrice?.listPrice &&
                    customerPrice?.laminatesUpcharge
                  ) ?
                    `Premium Laminate Upcharge: $${parseFloat(
                      customerPrice?.laminatesUpcharge?.listPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  `
                  : ''}
                </div>
                <div>
                  {(
                    !loadingPrice &&
                    customerPrice?.basePrice?.listPrice &&
                    customerPrice?.premEdgeUpcharge?.listPrice
                  ) ?
                    `Premium Edge Upcharge: $${parseFloat(
                      customerPrice?.premEdgeUpcharge?.listPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                 `
                  : ''}
                </div>

                <div>
                  {(
                    !loadingPrice &&
                    customerPrice?.basePrice?.listPrice &&
                    (customerPrice?.laminatesUpcharge || customerPrice?.premEdgeUpcharge)
                  ) ?
                    `Total: $${parseFloat(
                      (Number(customerPrice?.laminatesUpcharge?.listPrice) ||
                        Number(customerPrice?.laminatesUpcharge)) +
                        (Number(customerPrice?.premEdgeUpcharge?.listPrice) ||
                          Number(customerPrice?.premEdgeUpcharge)) +
                        Number(customerPrice?.basePrice?.listPrice)
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                 `
                  : ''}
                </div>
              </div>
              {(
                parseFloat(
                  (Number(customerPrice?.laminatesUpcharge?.unitPrice) ||
                    Number(customerPrice?.laminatesUpcharge)) +
                    (Number(customerPrice?.premEdgeUpcharge?.unitPrice) ||
                      Number(customerPrice?.premEdgeUpcharge)) +
                    Number(customerPrice?.basePrice?.unitPrice)
                )
              ) ?
                <div>
                  <h4 style={{ marginTop: '0px', marginBottom: '5px' }}>Dealer Price:</h4>
                  <div
                    style={{
                      height: '20px',
                      borderRadius: '0px',
                    }}
                    className={
                      !customerPrice?.basePrice?.listPrice || loadingPrice ?
                        'loading-price'
                      : 'modelNum'
                    }
                  >
                    Item Price: $
                    {parseFloat(customerPrice?.basePrice?.unitPrice).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                  <div>
                    {(
                      !loadingPrice &&
                      customerPrice?.basePrice?.unitPrice &&
                      customerPrice?.laminatesUpcharge
                    ) ?
                      `Premium Laminate Upcharge: $${parseFloat(
                        customerPrice?.laminatesUpcharge?.unitPrice
                      ).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  `
                    : ''}
                  </div>
                  <div>
                    {(
                      !loadingPrice &&
                      customerPrice?.basePrice?.unitPrice &&
                      customerPrice?.premEdgeUpcharge?.unitPrice
                    ) ?
                      `Premium Edge Upcharge:
                  $${parseFloat(customerPrice?.premEdgeUpcharge?.unitPrice).toLocaleString(
                    'en-US',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  `
                    : ''}
                  </div>

                  <div>
                    {(
                      !loadingPrice &&
                      customerPrice?.basePrice?.unitPrice &&
                      (customerPrice?.laminatesUpcharge || customerPrice?.premEdgeUpcharge)
                    ) ?
                      `Total: $${parseFloat(
                        (Number(customerPrice?.laminatesUpcharge?.unitPrice) ||
                          Number(customerPrice?.laminatesUpcharge)) +
                          (Number(customerPrice?.premEdgeUpcharge?.unitPrice) ||
                            Number(customerPrice?.premEdgeUpcharge)) +
                          Number(customerPrice?.basePrice?.unitPrice)
                      ).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                 `
                    : ''}
                  </div>
                </div>
              : ''}
            </div>
          : <>
              <div>Custom Pricing - please reach out to a sales rep for your price.</div>
            </>;
      case 'Certifications':
        return (
          <>{product.productInfo.Certifications ? `${product.productInfo.Certifications}` : ''}</>
        );
      default:
        return '';
    }
  }

  return (
    <>
      <div className='detail-titles-scroll'>
        <div className='detail-titles'>
          {detailTitles.map((d) => {
            return (
              <div
                role='button'
                aria-label={d}
                tabIndex={0}
                key={d}
                className={
                  selectedDetails === d ? 'detail-title detail-title-selected' : `detail-title`
                }
                onClick={() => {
                  setSelectedDetails(d);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setSelectedDetails(d);
                  }
                }}
              >
                {d}
              </div>
            );
          })}
        </div>
      </div>
      <div className='details-content'>
        <div className='switch-details'>{switchDetails()}</div>
      </div>
    </>
  );
};

export default DetailsDisplay;
