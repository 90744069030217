import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  product: '',
  type: '',
  series: [],
  titleSelected: true, //true for config, false for finishes
  noConfigs: false,
  configTypes: [],
  finishTypes: [],
  accessoriesAvailable: [],
  noFinishes: false,
  filterExceptionList: [],
  quickShipProducts: [],
  skuChangeList: {},
  skuCompleted: false,
  optionsSelected: {}, //array with objects, config option & value selected
  accessoriesSelected: [],
  configSelected: false,
  openAddOns: 'addOn',
  fullResponse: '',
  skuSeries: '',
  skuDisplayed: '',
  allProducts: [],
  filteredProducts: 0,
  found: '',
  mainImage: [],
  mainImageIndex: 0,
  showQuickshipIcon: false,
  openAddToCart: false,
  notification: '',
  unauthorized: false,
  parentCat: '',
  topHexCode: '#989997',
  benchHexCode: '#989997',
  stoolHexCode: '#121212',
  quantity: 1,
  nonBoxed: false,
  skuStatus: 'Completed',
  customerPrice: {},
  laminateUpcharge: {},
  premEdge: [],
  totalPrice: '',
  accessoryPrices: [],
  validUrlParams: false,
  showStagePromoVideo: false,
  stageBuilder: false,
  addStage: false,
  clearedSelectedOptions: false, //when sku is cleared- only sets when on 3d view
  view3d: false,
  configuraMissingColors: {},
  loadingPrice: false,
};

export const productStateSlice = createSlice({
  name: 'productState',
  initialState: {
    value: initialState,
  },
  reducers: {
    //set any of the product's states
    resetStateAction: (state) => {
      state.value = { ...initialState };
    },
    updateProductInfo: (state, action) => {
      state.value = { ...state.value, ...action.payload };
    },
    setCurrentProduct: (state, action) => {
      setCurrentProductFunction(state, { ...action.payload });
    },
    changeProduct: (state) => {
      state.value = {
        ...state.value,
        optionsSelected: {},
        configSelected: false,
        openAddOns: false,
        clearedSelectedOptions: state.value.view3d ? true : false,
        configuraMissingColors: {},
        accessoriesSelected: [],
      };
    },
    findAndSetProduct: (state, action) => {
      var returnObj = filterProducts(state);
      setCurrentProductFunction(state, {
        productID: action.payload.productID,
        firstProduct: returnObj,
        firstImage: returnObj?.cdnLinks[0]?.Image,
      });
      removeUnneededOption(state, returnObj);
    },
    changeTrueToVal: (state) => {
      //change true string to true constiable for add ons
      var { optionsSelected } = state.value;
      for (let i = 0; i < Object.keys(optionsSelected).length; i++) {
        if (
          optionsSelected[Object.keys(optionsSelected)[i]] === 'true' ||
          optionsSelected[Object.keys(optionsSelected)[i]] === 'TRUE'
        ) {
          var holderObj = { ...optionsSelected };
          holderObj[Object.keys(optionsSelected)[i]] = true;
          state.value = {
            ...state.value,
            optionsSelected: holderObj,
          };
        }
      }
    },
    setShowQuickShipIcon: (state) => {
      var { quickShipProducts, product, skuDisplayed } = state.value;
      if (
        quickShipProducts?.some((i) => {
          if (product?.configOptions?.Seating?.selectionName?.toLowerCase() === 'stool table') {
            const seating = product?.configOptions?.['Stool Color'];
            const manipulatedSku2 =
              seating ?
                skuDisplayed.slice(0, seating?.pos - 1) +
                'xx' +
                skuDisplayed.slice(seating?.pos + seating?.charLen - 1)
              : skuDisplayed;
            console.log(manipulatedSku2);
            return i.title.toLowerCase() === manipulatedSku2.toLowerCase();
          } else {
            return i.title.toLowerCase() === skuDisplayed.toLowerCase();
          }
        })
      ) {
        state.value = {
          ...state.value,
          showQuickshipIcon: true,
        };
      } else {
        state.value = {
          ...state.value,
          showQuickshipIcon: false,
        };
      }
    },
    validateParams: (state, action) => {
      var { allProducts, configTypes } = state.value;
      var { searchParamsUse } = action.payload;
      //check if the search params match any of the available skus
      if (allProducts.length) {
        if (
          allProducts.some((product) =>
            Object.entries(searchParamsUse).every(([key, value]) => {
              const newKey = key === 'Premium Edge Color' ? 'Edge Color' : key;
              //if key is an addOn, the only valid value is 'true'
              if (configTypes.addOns[key]) {
                if (typeof value === 'string' && value.toLowerCase() === 'true') {
                  searchParamsUse = { ...searchParamsUse, [key]: true };
                }
                return (
                  product.configOptions[key] &&
                  ((typeof value === 'string' && value.toLowerCase() === 'true') ||
                    (typeof value === 'boolean' && value))
                );
              }
              //if key is a finish, check if the value exists in the finishes list
              //(if the product doesnt have that finish i.e. seat color for wrong seat type, it will be removed in removeUnneededOption())
              else if (Object.keys(configTypes.finishes).includes(newKey)) {
                if (
                  configTypes.finishes[newKey][key].values.some((obj) =>
                    Object.entries(obj).some(
                      ([key, val]) =>
                        key === 'selectionName' && val.toLowerCase() === value.toLowerCase()
                    )
                  )
                ) {
                  return true;
                }
              }
              //if regular config, check if matches- not case sensitive
              else if (product?.configOptions[key]) {
                const configOption = product?.configOptions[key];
                if (
                  configOption.selectionName.toString().toLowerCase() ===
                  value.toString().toLowerCase()
                ) {
                  //if case doesn't match, set the value to the case in configOption
                  if (configOption.selectionName !== value) {
                    searchParamsUse = {
                      ...searchParamsUse,
                      [key]: configOption.selectionName,
                    };
                  }
                  return true;
                }
              } else {
                return false;
              }
            })
          )
        ) {
          state.value = {
            ...state.value,
            optionsSelected: searchParamsUse,
            validUrlParams: true,
          };
        } else {
          state.value = {
            ...state.value,
            optionsSelected: {},
            validUrlParams: false,
          };
          console.error(
            'Search parameters do not match any available configurations!',
            searchParamsUse
          );
        }
      }
    },
    //resets all states to initial value except for the values that are passed in the action
    clearAndResetStateAction: (state, action) => {
      state.value = { ...initialState, ...action.payload };
    },
  },
});

const setCurrentProductFunction = (state, payload) => {
  var {
    productID,
    firstProduct,
    firstImage,
    fullList = state.value.allProducts,
    firstRender = false,
  } = payload;
  if (firstRender === false || productID === undefined) {
    state.value = {
      ...state.value,
      product: firstProduct,
      mainImage:
        firstImage ? firstImage : (
          'https://res.cloudinary.com/da3rom333/image/upload/v1729007847/DONT%20MOVE/image-coming-soon_lzhzdk_sm6cln.jpg'
        ),
    };
  } else {
    var index = fullList.map((e) => e.modelNum.toString()).indexOf(productID);
    state.value = {
      ...state.value,
      product: fullList[index],
      mainImage: fullList[index].cdnLinks[0].Image,
    };
  }
};

function filterProducts(state) {
  var { optionsSelected, filterExceptionList, allProducts } = state.value;
  let holderArray = [];
  var optionsSelectedFilterable = {};
  for (var i in optionsSelected) {
    if (filterExceptionList.indexOf(i) < 0) {
      optionsSelectedFilterable[i] = optionsSelected[i];
    }
  }
  if (Object.keys(optionsSelectedFilterable).length) {
    for (var i = 0; i < allProducts.length; i++) {
      let include = true;
      for (var j in optionsSelectedFilterable) {
        if (j in allProducts[i].configOptions) {
          if (
            optionsSelectedFilterable[j] !== allProducts[i].configOptions[j].selectionName &&
            optionsSelectedFilterable[j] !== false
          ) {
            include = false;
            break;
          }
        } else {
          include = false;
          break;
        }
      }
      if (include) {
        holderArray.push(allProducts[i]);
      }
    }
  } else {
    holderArray = allProducts;
  }
  state.value = {
    ...state.value,
    filteredProducts: holderArray,
  };
  if (holderArray) {
    return holderArray[0];
  }
}

// Remove options that are not allowed when seat type changes
function removeUnneededOption(state, returnObj) {
  var { optionsSelected, finishTypes } = state.value;
  // Iterate through selected options
  for (let i = 0; i < Object.keys(optionsSelected).length; i++) {
    // Check if configOptions is an object (not an array) and is defined
    if (!Array.isArray(returnObj?.configOptions) && returnObj?.configOptions) {
      // Check if there are configOptions
      if (Object.keys(returnObj?.configOptions).length) {
        // Check if the selected option is not allowed
        if (!Object.keys(returnObj.configOptions).includes(Object.keys(optionsSelected)[i])) {
          removeOption(state, Object.keys(optionsSelected)[i]);
        }
      }
    }
    // Remove laminate color if it is not offered for the selected Edge type
    if (optionsSelected['Premium Edge Color']) {
      var colorList = finishTypes['Edge Color']?.values.filter((edgeColor) => {
        return edgeColor.Type === returnObj?.configOptions?.Edge?.selectionName;
      });
      if (colorList) {
        var remove = true;
        for (var j = 0; j < colorList?.length; j++) {
          if (colorList[j].selectionName === optionsSelected['Premium Edge Color']) {
            remove = false;
          }
        }
        if (remove) {
          removeOption(state, 'Premium Edge Color');
        }
      }
    }
  }
}

function removeOption(state, key) {
  var { optionsSelected } = state.value;
  var holderObj = { ...optionsSelected };
  delete holderObj[key];
  state.value = {
    ...state.value,
    optionsSelected: holderObj,
  };
}

export const {
  resetStateAction,
  updateProductInfo,
  setCurrentProduct,
  changeProduct,
  findAndSetProduct,
  changeTrueToVal,
  setShowQuickShipIcon,
  validateParams,
  clearAndResetStateAction,
} = productStateSlice.actions;
export default productStateSlice.reducer;
